$blue: #007ccb;
$blue-lighten: lighten($blue, 8%);
$dark-blue: #1d527f;
$light-blue: #bfe2f4;
$light-blue-lighten: lighten($light-blue, 12%);
$grey: #474747;
$grey-light: #aba8a6;
$blue-title: $dark-blue;
$cyan: #00a0a6;
$dark-cyan: #006a6e;
$rubine-red: #ea4278;
$dark-rubine-red: #993062;
$red-danger: #ea4242;
$dark-red-danger: #bf3535;
$text-color: #32607D;

/* New colors */
/* Primary */
$primary-blue: #007CCB;
$blue-hover: #1d527f;
$secondary-blue: #BEE7F8;
$white: #FFFFFF;
$white-translucid: #ffffffc4;
$background: #F8FAFB;
$text-color: #32607D;
$button-gradiant: transparent linear-gradient(225deg, $primary-blue 0%, #36A0EA 100%) 0% 0% no-repeat padding-box;

/* Secondary */
$blue-light: #E3F7FF;
$blue-extra-light: #F1FBFF;
$gray-light: #E6EBEE;
$gray: #BECAD2;
$dark-gray: #7E9AAC;
$dark: #203F54;

/* Info colors */
$blue: $primary-blue;
$red: #FF5364;
$red-light: #fdedee;
$orange: #FFAA84;
$orange-light: #FFF6F2;
$yellow: #FFCE48;
$yellow-light: #FDF9ED;
$green: #5CE882;
$green-light: #EEFDF2;
$purple: #8e5c96;
$purple-light: #f6f2f7;

/*bootstrap override*/
$font-size-base: 1rem !default;
$h2-font-size: $font-size-base * 1.75;
$h700-font-size: $font-size-base * 1.5;
$font-size-xxl: $font-size-base * 5;
$black: #000;
$white: #fff;
$font-weight-normal: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-bold: $font-weight-500;
$font-weight-bolder: $font-weight-bold;

/*bootstrap override*/
$primary: $blue;
$blue-hover: #0163A2;
$primary-hover: $dark-blue;
$danger: $rubine-red;
$secondary-hover: #495057;
$info: $blue;
$accent: $rubine-red;
$secondary: $white;
$gray-100: #f8f9fa;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #ced4da;
$gray-600: #6C757D;
$gray-800: #343A40;
$gray-900: #474747;

$input-border-color: $gray-400;

$table-active-bg-factor: .1 !default;


$font-family-sans-serif: "IntrumSans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;

$input-bg: $white;

$body-bg: $gray-100;
$body-color: $gray-900;

$accordion-bg: $white;
$accordion-button-active-bg: $white;
$accordion-button-active-color: $body-color;
$accordion-button-focus-border-color: $white;
$accordion-button-focus-box-shadow: $white;
$accordion-border-radius: 0;

$badge-font-size: 1em;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: .5em;

$link-color: #aba8a6;

$card-border-radius: 0;

$breadcrumb-bg: inherit;
$breadcrumb-divider-color: #aba8a6;
$breadcrumb-active-color: #474747;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 8px;
$breadcrumb-margin-bottom: 0;

$table-group-separator-color: none;
$table-active-bg: $gray-400;
$table-cell-padding-x-sm: 0.75rem;


$link-decoration: none;

$grid-gutter-widths: (
        xs: 24px,
        md: 16px,
        lg: 24px,
        xl: 24px
);

$container-max-widths: (
        xxl: 1500px
)
