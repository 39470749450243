@import "fonts.css";
@import "variables";
@import "functions";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";
@import '~react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: $grid-gutter-width / 2;
    .container, [class*='container-'] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
    .row:not(.no-gutters) {
      margin-right: -$grid-gutter-half-width;
      margin-left: -$grid-gutter-half-width;
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
      padding-right: $grid-gutter-half-width !important;
      padding-left: $grid-gutter-half-width !important;

      &.col-main {
        background: set-background-color($grid-breakpoint, $white);
        border-top: if($grid-breakpoint=='xs', $border-width solid $border-color !important, 0 !important);
        border-bottom: if($grid-breakpoint=='xs', $border-width solid $border-color !important, 0 !important);
        padding-top: if($grid-breakpoint=='xs', $grid-gutter-half-width, 0);
        padding-bottom: if($grid-breakpoint=='xs', $grid-gutter-half-width, 0);
      }

      .card {
        border-radius: 5px !important;
        border-color: if($grid-breakpoint=='xs', rgba($black, .0), $card-border-color);
        margin-bottom: if($grid-breakpoint=='xs', 0, 1rem);

        .card-body {
          padding: if($grid-breakpoint=='xs', 0, $card-spacer-x)
        }
      }
    }
    .offcanvas-end {
      width: if($grid-breakpoint=='xs', 75%, $offcanvas-horizontal-width)
    }

  }
}

.card {
  border-radius: 5px;
}

body {
  > * {
    font-family: $font-family-sans-serif;
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-bottom: .75rem;
}

/* Solid border */
hr.solid {
  border-top: 1px solid $gray-400;
  margin: auto;
  width: calc(100% - 16px);
}

p {
  margin: 0 !important;
}

textarea {
  height: 10rem;
  border-radius: 5px;
  border: solid 1px #ced4da;
}

.statistics {
  gap: 1rem;
  margin: .5rem 0;

  .card-stats {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 $gray-400;
    align-items: center;
    padding-bottom: .5rem;

    .timeline {
      padding: 1rem 1.5rem !important;

      .no-reminders {
        display: flex;
        justify-content: center;
      }
    }

    .justify-center {
      justify-content: center;
    }
  }

  .card-value {
    font-size: 5rem;
    font-weight: 700;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 1rem;
  }

  .card-subtitle {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .red {
    color: $rubine-red;
  }

  .purple {
    color: $purple;
  }

  .dark-blue {
    color: $dark-blue;
  }

  .cyan {
    color: $cyan;
  }

  .blue {
    color: $blue;
  }

}

.card-container {
  .space-between {
    justify-content: space-between;
  }

  hr {
    color: $gray-600;
    margin: 1rem;
  }

  .card-content {
    padding: 1rem;
  }

  .card-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    color: $gray-600;
    font-weight: $font-weight-500;
    font-size: 1.1rem;
    padding: 1rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  // Overrides bootstrap card.scss
  .card-link + .card-link {
    margin-left: 0 !important;
  }

  .card-link:hover {
    background-color: $gray-200;
    cursor: pointer;
  }

  .card-link-active {
    background-color: $light-blue-lighten;
    color: $blue;
    font-weight: $font-weight-500;
    font-size: 1.1rem;
    padding: 1rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .card-left {
    border-right: none !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0 !important;
  }

  .card-left-header {
    margin: 0 !important;
    color: $grey;
    font-weight: $font-weight-500;
    padding: 1rem;
    text-align: center;
  }

  .card-right {
    border-left: none !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 0 .5rem 1rem !important;
  }

  .card-tab-content {
    //margin: 1rem;
  }

  .info-row {
    padding: .5rem 0;
  }

  .info-row-list {
    padding-bottom: .5rem;
  }

  .info-title {
    color: $gray-600;
    font-weight: $font-weight-500;
    font-size: 1.1rem;
  }

  .info-value {
    color: $grey;
    font-weight: $font-weight-500;
    font-size: 1.1rem;
  }

  .info-list-value {
    margin-top: 0;
    color: $grey;
    font-weight: $font-weight-500;
    font-size: 1.1rem;
  }

  .tab-title {
    padding: 1rem 0;
    margin: 0;
  }
}

.info-row {
  padding: .5rem 0;
}

.info-title {
  color: $gray-600;
  font-weight: $font-weight-500;
  font-size: 1.1rem;
}

.info-value {
  color: $grey;
  font-weight: $font-weight-500;
  font-size: 1.1rem;
}

.btn.btn-validation, .btn-close-modal {
  background: $primary;
  width: 7rem;
  margin: auto;
  border-color: $primary;

  &:hover {
    background-color: $primary-hover !important;
    border: none !important;
  }

  &:focus {
    background-color: $primary-hover !important;
    border: none !important;
  }
}

.validate {
  background: $primary;
  border-color: $primary;

  &:hover {
    background-color: $primary-hover !important;
    border: none !important;
  }

  &:focus {
    background-color: $primary-hover !important;
    border: none !important;
  }
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background: none;
}

.form-group:not(:first-child) {
  margin-top: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  margin-bottom: .5rem;
  margin-left: .25rem;
  line-height: 1rem;
}

.form-button-group {
  margin-top: 1.75rem;
  text-align: center;

  button:not(:first-child) {
    margin-left: 1.5rem;
  }
}

.bg-intrum-primary {
  background: $blue;
}

.bg-intrum-primary-light {
  background: $blue-lighten;
}

.text-secondary {
  color: $grey !important
}

.text-gray-600 {
  color: $gray-600 !important
}

.text-gray-800 {
  color: $gray-800 !important
}

.text-secondary-light {
  color: $grey-light !important
}

.text-primary {
  color: $primary
}

.text-title, .link-title {
  color: $blue-title;
}

.font-weight-600 {
  font-weight: $font-weight-600
}

.font-weight-700 {
  font-weight: $font-weight-700
}
.font-size-14 {
  font-size: 14px;
}
.font-size-base {
  font-size: $font-size-base;
}

.font-size-xxl {
  font-size: $font-size-xxl;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.h700-font-size {
  font-size: $h700-font-size;
}

.line-heigt-normal {
  line-height: normal;
}

.border-right-dashed-cyan {
  border-width: 1px;
  border-right-style: dashed;
  border-color: $cyan;
}

.border-right-cyan {
  border-width: 1px;
  border-right-style: solid;
  border-color: $cyan;
  height: 100%;
}

.border-right-dashed-secondary {
  border-width: 1px;
  border-right-style: dashed;
  border-color: $grey-light;
}

.border-right-secondary {
  border-width: 1px;
  border-right-style: solid;
  border-color: $grey-light;
}

.filter-badge {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  .close-icon {
    margin-left: .25rem;
    cursor: pointer;
  }
}

.filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .badge {
    margin-left: .5rem;
  }
}

.filter-dropdown {
  margin-left: 1rem;

  .btn-light, .btn-light:active, .btn-light:focus {
    background-color: $white;
    border-color: $white;
    color: $gray-800;
  }

  .dropdown-item {
    &:active, &:focus {
      border: none;
      background-color: $gray-400;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.text-intrum-cyan {
  color: $cyan;
}

.text-intrum-blue {
  color: $blue;
}

.document-container {
  display: grid;
  place-items: center;
  width: 100% !important;
  height: 100% !important;
}

.vega-embed {
  width: 100%;
}

.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

section {
  margin-bottom: 1.5rem
}

.logo-title {
  font-size: $h1-font-size;
  color: $white;
}

.logo-title:hover {
  text-decoration: none;
  color: $white;
}

.title-container {
  display: flex;
  align-items: center;
}

.title-with-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 .75rem;
}

.created-at {
  padding-top: 1rem;
}

.custom-accordion {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  .accordion-center {
    display: flex;
    justify-content: center;
  }

  .accordion-item {
    border: none;
    border-radius: 5px !important;
    box-shadow: 0 1px 4px 0 $gray-400;
  }

  .accordion-header {
    border-radius: 5px !important;
  }

  .accordion-button {
    border-radius: 5px !important;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .accordion-button[aria-expanded=true] {
    background-color: $light-blue-lighten;
    color: $blue;
    font-weight: $font-weight-500;
    border: none !important;
  }
}

.sub-header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.template {
  white-space: pre-line !important;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .code-editor-container {
    width: 100%;
  }

  .code-editor {
    //border: solid $gray-400 1px;
    border-radius: 5px;
    font-size: 12px;
    background-color: $white;
    font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace;
    border-right: solid $gray-200 1px;
    min-height: 10rem;
  }

  .code {
    display: flex;
    padding: 0;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 1px 4px 0 $gray-400;
    overflow-y: scroll;
    height: 35rem;
    width: 100%;
  }

  .lines-indicator {
    width: 60px;
    padding: 15px;
    font-size: 12px;
    font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace;
    text-align: end;
    color: $gray-600;
    border-right: solid $gray-200 1px;
  }

  .preview-button {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }

  .template-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    gap: 1rem;
    margin-bottom: 3rem;
  }

  .html-template-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    gap: 1rem;
    margin-bottom: 3rem;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 $gray-400;
    padding: 1rem;
  }

  .w-tc-editor code[class*='language-'] .token.tag, .w-tc-editor pre[class*='language-'] .token.tag {
    color: $rubine-red !important;
  }

  .w-tc-editor-preview pre code {
    color: $gray-600;

    .attr-name {
      color: $cyan !important;
    }

    .attr-value {
      color: $purple !important;
    }

    .language-twig {
      color: $blue;
    }

    .property {
      color: $blue !important;
    }

    .number {
      color: $dark-cyan !important
    }

    .string {
      color: $rubine-red !important;
    }

    .ponctuation {
      color: $gray-600 !important;
    }
  }


}

.btn {
  &:not(.btn-link) {
    padding: 0 1rem;
    height: 40px;
    font-size: 1rem;
    font-weight: 500;
  }

  &.btn-secondary {
    border-color: $input-border-color;
    color: $gray-600
  }

  &.btn-secondary:hover {
    background-color: $secondary-hover;
    border-color: $secondary-hover;
    color: $gray-100
  }

  &.btn-secondary:focus {
    background-color: $secondary-hover;
    border-color: $secondary-hover;
    color: $gray-100;
    box-shadow: 0 0 0 0.25rem rgba(73, 80, 87, 0.3);
  }

  &.btn-primary {
    color: $white;
  }

  &.btn-primary:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
    color: $gray-100;
  }

  &.btn-primary:focus {
    background-color: $primary-hover;
    border-color: $primary-hover;
    color: $gray-100;
    box-shadow: 0 0 0 0.25rem rgba(0, 124, 203, 0.3);
  }

  &.button-link {
    font-size: 1rem;
    font-family: $font-family-sans-serif;
    padding: 0 !important;
  }
}

.pills-list {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  font-size: .85rem;
}

.show-hide {
  font-size: 1.1rem !important;

  &:hover {
    text-decoration: underline;
  }
}

.btn-arrow {
  color: $primary;

  &:hover {
    color: $primary-hover;
  }

  &:focus {
    color: $primary-hover;
    outline-color: $blue-title
  }
}

.btn-chevron {
  cursor: pointer;

  &:hover {
    color: $secondary-hover;
  }

  &:focus {
    color: $secondary-hover;
    outline-color: $blue-title
  }
}

.btn-chevron[aria-disabled="true"] {
  color: $gray-400;
}

a {
  color: $gray-600;
  font-weight: 500;
  font-size: 1rem;

  &:hover {
    color: $secondary-hover;
    text-decoration: underline;
  }

  &:active {
    color: $secondary-hover;
    text-decoration: underline;
  }

  &:focus {
    color: $secondary-hover;
    border: 3px solid $blue-title;
    border-radius: 4px;
  }
}

.bold {
  color: $gray-600;
  font-weight: 500;
  font-size: 1rem;
}

.italic {
  color: $gray-600;
  font-weight: 300;
  font-size: 1rem;
  font-style: italic;
}

.link-standard {
  color: $gray-600;
  font-weight: 500;

  &:hover {
    color: $secondary-hover;
    text-decoration: underline;
  }

  &:active {
    color: $secondary-hover;
    text-decoration: underline;
  }

  &:focus {
    color: $secondary-hover;
    border: 3px solid $blue-title;
    border-radius: 4px;
  }
}

.link-standard.no-focus {
  border: none;
}

.p-05 {
  padding: 0.5rem !important;
}

.link-inciting {
  color: $blue;
  font-weight: 500;

  &:hover {
    color: $blue-hover;
    text-decoration: underline;
  }

  &:active {
    color: $blue-hover;
    text-decoration: underline;
  }

  &:focus {
    color: $blue-hover;
    border: 3px solid $blue-title;
    border-radius: 4px;
  }
}

.form-select {
  option:disabled {
    background-color: $gray-200;
    font-style: italic;
  }
}

.form-control:focus, .form-select:focus {
  border-color: $blue;
  box-shadow: 0 0 4px #007CCBCC;
}

.form-check-input[type="checkbox"] {
  border-color: $primary;
  border-radius: 4rem;
  height: 1.15rem;
  width: 3rem;

  &:focus {
    box-shadow: 0 0 0 0.25rem lighten($primary, 30%);
  }
}

.image-zoom {
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 4px 0 $gray-400;

  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
  border-radius: 12px;
}

.reminders {
  strong {
    white-space: break-spaces;
    font-size: 1.25rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
  }

  .log-item-detail div:first-child { /* item date */
    font-size: 1rem;
    color: $gray-800;
    margin-top: 0.5rem;
  }

  a {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
    display: block;
  }

  p {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: $gray-600;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .log-item:not(:first-child) {
    .log-item-detail {
      margin-bottom: 2rem;
      min-height: 2rem;
    }
  }
}

.button-with-icon {
  gap: .25rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    margin-bottom: 0;
    margin-right: .25rem;
  }

  &:hover {
    text-decoration: underline;
    color: $primary;
  }
}

.col-main {
  @extend .mb-2;
  @extend .px-3;
}

.edit-button {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  background-color: $cyan;
  border: none !important;

  &:hover {
    background-color: $dark-cyan !important;
    border: none !important;
  }

  &:focus {
    background-color: $dark-cyan !important;
    border: none !important;
  }
}

.add-button {
  gap: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cyan;
  border: none !important;

  &:hover {
    background-color: $dark-cyan !important;
    border: none !important;
  }

  &:focus {
    background-color: $dark-cyan !important;
    border: none !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 105, 109, .3) !important;
  }
}

.menu-main {
  @extend .py-0;

  height: 72px;

  .container-fluid {
    height: 100%;
    @extend .px-0;

    .navbar-brand {
      margin: 0;
      padding: 0 1.5rem;
      height: 100%;
      @extend .d-flex;
      @extend .align-items-center;

      > a {
        border: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .container-search-icon {
      padding: 0.3rem 0.4rem;
      margin-right: 5vw;
      border-radius: 1.2rem;
      background-color: rgba(255, 255, 255, 0.2);
      font-size: 0.75rem;
    }


    .me-auto {
      height: 100%;

      > a {

        text-decoration: none;
        border-radius: 0;
        border: none;
        font-weight: normal;
        height: 100%;

        border-bottom: 4px solid transparent;
        color: #343a40;
        @extend .pt-4;
        padding-left: 2.25rem;
        padding-right: 2.25rem;

        &.active-page {
          color: $primary;
          border-bottom: 4px solid $primary;
          font-weight: normal;
        }

        svg {
          @extend .me-2
        }
      }
    }

    .user-menu {
      margin-right: 1.5rem;
      height: 100%;


      > .btn-link {
        color: $primary;
        @extend .d-flex;
        @extend .align-items-center;

        :first-child {
          @extend .me-2;
        }
      }

      > .btn-link:hover {
        color: $primary-hover;
      }

      > .btn-link:focus {
        box-shadow: none;
      }

      .dropdown-toggle {
        &::after {
          content: none;
        }
      }

      .dropdown-menu {
        padding-bottom: 0;

        .dropdown-divider {
          margin-bottom: 0;
          margin-top: 0.25rem;
        }


        .icon-user {
          color: $gray-500;
        }


        .dropdown-item-text {
          width: max-content;
          @extend .d-flex;
          @extend .align-items-center;
        }
      }

    }
  }
}

.details-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  width: 1rem;
  border-radius: 2rem;
  background: none !important;
  border: none;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.shadow-nav {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.error-message {
  color: $rubine-red;
  font-style: italic;
  font-size: .9rem
}

.datatable-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link-button {
    border: none;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background: $primary;
    color: white;
    border-radius: 4px;

    &:hover {
      text-decoration: none;
      background-color: lighten($primary, 15%);
    }

    &:focus {
      outline: none;
      text-decoration: none;
      background-color: darken($primary, 15%);
    }
  }
}

.required {
  color: $rubine-red;
}

.required-tip {
  font-style: italic;
}

.help-tooltip {
  background: none;
  color: $gray-600;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.default-input-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.default-input {
  height: 2.5rem;
  border: solid 1px $gray-400;
  border-radius: 3px;
  padding: .5rem;

  &:focus {
    border-radius: 5px;
    outline: none;
    border: solid 1px $blue;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.3);
    transition: 0.2s;
  }
}

.user-card {
  //display: flex;
  padding: 1rem;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 $gray-400;

  .reset-button {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  .user-icon {
    color: $blue;
  }

  .title {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      gap: 1rem;
    }
  }

  .line-end {
    display: flex;
    justify-content: flex-end;
    gap: .5rem;
  }

  .line {
    margin-left: 3rem;

    .user-info {
      color: $gray-600;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
}

.create-account-page {
  .alert {
    margin: auto;
    width: calc(100% - 1.5rem);
  }

  p {
    margin-bottom: 0;
  }

  .form-card {
    padding: 1rem;

    .row {
      margin: .5rem 0;
    }

    hr {
      width: 90%;
      margin: 2rem auto;
    }
  }

  .display-password {
    font-weight: 500;
    cursor: pointer;
  }

  .label-group {
    display: flex;
    justify-content: space-between;
  }
}

.demo-switch, .user-level-switch {
  display: flex;
  gap: .5rem;
  align-items: center;
  height: 2.5rem;
}

.error, .error:focus {
  border: solid 1px $rubine-red !important;
  border-radius: 5px !important;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.3) !important;
  }
}

.search-group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .field-group {
    flex: 1;
  }

  .search-button {
    width: 2.5rem !important;
    cursor: pointer;
    color: $white;
    background-color: $primary;
    border: none;
    border-radius: 4px;
    padding: .5rem 0;
    font-weight: 500;
    margin-left: .5rem;

    &:disabled {
      background-color: lighten($primary, 20%);
    }

    &:hover {
      background-color: lighten($primary, 5%)
    }

    &:focus {
      background-color: darken($primary, 15%);
    }

    &.red {
      background-color: $red-danger !important;
    }

    &.green {
      background-color: $cyan !important;
    }
  }

  .search-input {
    width: 100%;
  }
}

.col {
  display: flex;
  flex-direction: column;

  input {
    height: 2.5rem;
    border: solid 1px $gray-400;
    border-radius: 2px;
    padding: .5rem;

    &:focus {
      border-radius: 5px;
      outline: none;
      border: solid 1px $blue;
      box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.3);
      transition: 0.2s;
    }
  }
  textarea {
    border: solid 1px $gray-400;
    border-radius: 2px;
    padding: .5rem;
    &:focus {
      border-radius: 5px;
      outline: none;
      border: solid 1px $blue;
      box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.3);
      transition: 0.2s;
    }
  }
}

.download {
  &:hover {
    color: $gray-500;
  }
}

.toggle-group-active {
  background-color: $gray-100 !important;
}

.toggle-group-active.btn.btn-secondary:focus {
  color: #6C757D !important;
}

.toggle-group-active.btn.btn-secondary:hover {
  color: $gray-100 !important;
  background-color: $gray-700 !important;
}

.close-button {
  width: 2.5rem !important;
  cursor: pointer;
  color: $white;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  padding: .5rem 0;
  font-weight: 500;
  margin-left: .5rem;

  &:disabled {
    background-color: lighten($primary, 20%);
  }

  &:hover {
    background-color: lighten($primary, 5%)
  }

  &:focus {
    background-color: darken($primary, 15%);
  }
}

.submit-button {
  width: 150px;
  color: $white;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  padding: .5rem 0;
  font-weight: 500;

  &:hover {
    background-color: lighten($primary, 5%);
    cursor: pointer;
  }

  &:focus {
    background-color: darken($primary, 15%);
  }
}

.submit-button:disabled {
  background-color: lighten($primary, 20%);

  &:hover {
    background-color: lighten($primary, 20%);
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

section {
  &.table-filters-search-container {
    @extend .px-0;
    @extend .py-0;
    @extend .mb-3;
    @include media-breakpoint-down(sm) {
      .input-group {
        margin-bottom: 0.75rem;
      }
    }
  }
}

.text-end {
  text-align: end;
  margin-right: 1.5rem !important;
}

.success {
  color: $green;
}

.pending {
  color: $yellow;
}

.running, .created {
  color: $blue
}

.failed {
  color: $red-danger;
}

.delete-button {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  background-color: $red-danger;
  border: none !important;

  &:hover {
    background-color: $dark-red-danger !important;
    border: none !important;
  }

  &:focus {
    background-color: $dark-red-danger !important;
    border: none !important;
  }
}

table {
  &.table-font-size-sm {
    * {
      font-size: $font-size-sm;
    }
  }

  &.with-row-selection {
    border-top-width: 1px;
    border-top-style: solid;
  }

  &.table-sm {
    > :not(caption) > * > * {
      padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
    }
  }

  thead {
    th {
      @extend .text-nowrap;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: $gray-100;
    }
  }

  &.table-nostriped {
    tbody tr:nth-of-type(odd) {
      background-color: transparent;
    }
  }

  &.grid-simple {
    tfoot tr td {
      border: none;
    }
  }

  a {
    color: $blue;
    font-weight: $font-weight-normal;
  }

}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
}

.accordion-button.collapsed:hover {
  background-color: $gray-200;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-copy {
  cursor: copy;
}

.font-weight-normal {
  font-weight: $font-weight-normal
}

.workflow-edit {
  padding: 1rem;
}

.drop-zone {
  height: 60vh;

  &.upload-in-progress {
    height: 25vh;
  }

  &.drag-active {
    border-width: 5px;
    border-style: dotted;
  }

  .card-body {
    @extend .d-flex;
    @extend .flex-column;
  }
}

.widget-event-tile {
  @extend .ms-5;
  @extend .py-5;
  line-height: 1;

  span {
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;

    span {
      display: block;
      font-size: $font-size-base;
    }
  }
}

.help-widget {
  .btn-close {
    width: .5rem;
    height: .5rem;
  }
}

.dropzone-logo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.preview-logo {
  position: relative;
  height: 160px;
  width: 100%;
  border-color: #eeeeee;
  border-width: 2px;
  border-style: dashed;
  display: flex;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
    margin: 0 !important;
  }

  .action-preview-logo {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    background-color: rgba(5, 5, 5, 0.55);

    aside {
      margin-top: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
    }
  }
}

.preview-logo > div:first-of-type {
  height: 100%;
  position: relative;
}

.preview-logo:hover {
  opacity: 0.55;

  .action-preview-logo {
    display: flex;
  }
}


.tenant-info-page {
  .header-nav {
    padding: .5rem 1rem;
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: $blue;
    background-color: $gray-200;
  }

  .nav-link {
    color: $gray-800;
    cursor: pointer;
  }

  .tab-content {
    padding: 1rem;

    h4 {
      margin-bottom: 2rem;
    }

    ul {
      padding-left: 0;

      li {
        margin-left: 1.5rem;
        list-style-type: disc;
      }
    }

    img {
      display: block;
      margin: auto;
      width: 90%;
    }

    p {
      display: block;
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;
    }
  }
}

.tenants-switches-container {
  display: flex;

  .tenants-switches {
    margin: 1rem;
  }
}

.dashboard-page {
  .title {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 48px;
  }

  .event {
    //background-image: url('../images/img_dashboard.svg');
    background-repeat: no-repeat;
    background-position: 10px -10px;
    height: 393px;
    width: 1120px;

    .widget-event {
      padding-left: .5rem;
      padding-right: .5rem;

      a {
        text-decoration: none;
      }

      .card {
        border-radius: 4px;

        .card-body {
          height: 120px;
          font-weight: 500;

          .counter {
            line-height: normal;

            span {
              display: flex;
              flex-direction: column;
              font-size: 3.5rem;
              text-align: right;

              span {
                font-size: 1.25rem;
              }
            }
          }
        }

        .card-footer {
          height: 40px;
          overflow: hidden;

          a {
            color: #ffffff;
            display: contents;
          }
        }
      }

      .card.dispute {
        color: $primary;

        .card-footer {
          background-color: $primary;
          color: #ffffff;
        }
      }

      .card.discrepancy {
        color: $blue;

        .card-footer {
          background-color: $blue;
          color: #ffffff;
        }
      }

      .card.reminder {
        color: $cyan;

        .card-footer {
          background-color: $cyan;
          color: #ffffff;
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .mb-mobile {
    margin-bottom: 4.5rem !important;
  }
  .dashboard-page {
    .title {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 40px;
    }

    .event {
      background-image: none;
      height: auto;
      width: auto;
      margin-left: -12px;
      margin-right: -12px;
    }

    .widget-event {
      margin-bottom: .5rem;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .counter {
        margin-right: 1.5rem;
      }
    }
  }
}


.modal-dialog {

  .modal-title {
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 0.4rem;

    .modal-header {
      padding-top: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 0.75rem;
    }

    .modal-body {
      padding-top: 1.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}

.modal-confirmation {
  .form-button-group {
    margin-top: 1.25rem;
  }
}

.btn-reset {
  cursor: pointer;
  color: $gray-600;
  font-weight: 500;
  padding: 8px 0;
}

.dropdown-item-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.card .info-card {
  border: none;
  box-shadow: 0 4px 10px #7E9AAC29;
  border-radius: 15px;
  .card-header  {
    &.blue-header {
      background-color: #f3fafd;
      color: $blue;
    }
    &.purple-header {
      color: $purple;
      background-color: $purple-light;
    }
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: none;
    h5 {
      margin: 0;
      padding: .5rem 0;
    }
  }
}
.alert {
  color: $text-color !important;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  padding: 13px 1rem;

  &.alert-primary, &.alert-info {
    background-color: $blue-light !important;
    border-left: solid $primary-blue 5px;
  }

  &.alert-success {
    background-color: $green-light !important;
    border-left: solid $green 5px;
  }

  &.alert-warning {
    background-color: $yellow-light !important;
    border-left: solid $yellow 5px;
  }

  &.alert-warn-high {
    background-color: $orange-light !important;
    border-left: solid $orange 5px;
  }

  &.alert-error, &.alert-danger {
    background-color: $red-light !important;
    border-left: solid $red 5px;
  }
}
.status-pill {
  color: $white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__error {
    background-color: $red;
  }
  &__success {
    background-color: $green;
  }
  &__partial {
    color: $orange
  }
}

.pill {
  font-size: .75rem;
  background-color: $background;
  margin-bottom: .5rem;
  border-radius: 4px;
}
